

//----------BUTTONS----------//
function headerLogo() {
    window.open(
    "http://thelake.ro", "_self");
}
function NewTabNovarion() {
    window.open(
    "http://novarion.ro", "_blank");
}
function NewTabFb() {
    window.open(
    "https://www.facebook.com/thelakehome/", "_blank");
}
function NewTabLi() {
    window.open(
    "https://www.linkedin.com/company/the-lake-home-living-xperience/", "_blank");
}
function NewTabIg() {
    window.open(
    "https://www.instagram.com/thelakehomelx/", "_blank");
}
function NewTabYt() {
    window.open(
    "https://www.youtube.com/channel/UCjExjuoQ-Oui73BCi9WjQsg", "_blank");
}


  
    //--------------------CALCULATOR--------------------//
    function add()
    {
      
      var numOne, numTwo, sum;
      if((document.getElementById("first").value) > 6999){
          if (document.getElementById('age2').checked) {
            numTwo = document.getElementById('age2').value;}
          if (document.getElementById('age3').checked) {
            numTwo = document.getElementById('age3').value;}
          if (document.getElementById('age4').checked) {
            numTwo = document.getElementById('age4').value;}
    
          numOne = parseInt(document.getElementById("first").value);
            
          sum = numOne + (numOne * numTwo / 100);
          pro = numOne * numTwo / 100;
    
          document.getElementById("answer3").value = numTwo + "%";
    
          if(pro<1000){
              document.getElementById("answer2").value = pro + " €";
          }
          else {
              document.getElementById("answer2").value =(pro/1000).toFixed(3) + " €";
          }
          if(sum >1000){
              document.getElementById("answer").value = (sum/1000).toFixed(3) + " €";
          }
          else {
              document.getElementById("answer").value =sum + " €";
          }
          
      }
      else{
          alert("Suma minima de investiție este 7000 €");
          if ($(first).val().trim() == "") {$(first).val(7000);};
      }
    
    }
    